<template>
	<!-- Start Template Root Div -->
	<div>
		<header class="w-full sticky top-0 z-10">
			<div class="w-full bg-gray-700">
				<div class="container mx-auto py-2 lg:p-8 lg:py-3">
					<div
						class="w-full flex justify-between items-center p-2 text-white"
					>
						<div class="w-1/4 text-white p-5">
							<WaveLogo
								class="cursor-pointer h-16 md:h-12 lg:h-16"
							/>
						</div>
						<div class="hidden md:block">
							<h1 class="text-2xl">Sign Waiver</h1>
						</div>
						<div class="w-1/4 text-right">
							<p class="m-0">
								{{ new Date() | moment("dddd Do MMMM") }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</header>

		<main class="container mx-auto py-2 lg:p-8 lg:py-3">
			<section class="flex flex-col text-sm mb-20">
				<div class="w-full h-full relative">
					<div class="rounded shadow-lg bg-white p-4 mb-8">
						<!-- Heading -->
						<div class="w-8/12 lg:w-6/12 mx-auto my-4 h-auto">
							<h2 class="text-sherpa-500 text-lg">
								Enter your details
							</h2>
							<p class="text-gray-500 text-base">
								You will not be permitted to participate in any
								booked activity at The Wave until a waiver has
								been completed. Please enter your booking
								details to view and sign the waiver.
							</p>
						</div>
						<!-- End Heading -->

						<!-- Param Field for Email Address -->
						<div class="w-8/12 lg:w-6/12 mx-auto my-8 h-auto">
							<h3 class="text-sherpa-500 text-base">
								Email Address
							</h3>
							<div
								class="flex justify-start bg-white overflow-hidden mt-2 lg:mt-0"
							>
								<div class="text-center flex-1">
									<input
										type="email"
										class="w-full border rounded-l-xl rounded-r-xl px-4 py-2 bg-white text-gray-700 placeholder-gray-300 focus:outline-none focus:border-gray-900 font-semibold text-sm"
										placeholder="Enter Your Email Address"
										v-model="emailAddress"
									/>
								</div>
							</div>
							<p class="text-gray-400 text-sm">
								We'll never share your email with anyone else
							</p>
						</div>
						<!-- End Param Field for Email Address -->

						<!-- Submit Field -->
						<div
							class="w-8/12 lg:w-6/12 mx-auto my-4 h-auto flex justify-between items-center"
						>
							<button
								@click="setParams()"
								class="w-9/12 md:w-6/12 lg:w-3/12 bg-pear-500 hover:bg-pear-700 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full"
							>
								Continue
							</button>
							<div
								class="opacity-0 transition duration-200 p-2 mb-2 rounded-lg bg-red-200 text-center"
								ref="incorrectEmail"
							>
								Couldn't find your email address
							</div>
						</div>
						<!-- End Submit Field -->
					</div>
				</div>
			</section>

			<!-- - Loading - -->
			<WaveLoader v-if="loading" />
		</main>
	</div>
	<!-- End Template Root Div -->
</template>

<script>
	import WaveLogo from "@/components/WaveLogo.vue";
	import WaveLoader from "@/components/WaveLoader.vue";
	import { mapActions } from "vuex";

	export default {
		components: {
			WaveLogo,
			WaveLoader
		},
		data: () => ({
			validEmail: false,

			emailAddress: "",
			loading: false,

			waivers: [],
			waiverSign: null
		}),
		created() {
			// Clear Local Storage
			this.clearReservation();

			this.checkData();
		},
		methods: {
			// Vuex Mapped Actions from surfReservations Namespace:
			...mapActions({
				getReservation: "reservations/fetchReservation",
				clearReservation: "reservations/clearReservation"
			}),
			async checkData() {
				// Setup Plugin & Params
				await this.setupData();

				// Re-append Route if already filled in query params
				if (this.validEmail) {
					// Checked Finished Waiver
					if (this.waiverSign.getWaiversSignable().length < 1) {
						this.$router.push({
							name: "SurferWaiverConfirm",
							query: this.$route.query
						});
						return;
					}

					// Check Amount of Adults
					if (this.waiverSign.waiversAdults.length > 1) {
						this.$router.push({
							name: "SurferWaiverSelect",
							query: this.$route.query
						});
					} else {
						this.$router.push({
							name: "SurferWaiverDisclaimer",
							query: this.$route.query
						});
					}
				}
			},
			async setupData() {
				this.loading = true;

				// Parse Email Address
				this.emailAddress = this.$route.query.emailAddress
					? this.$route.query.emailAddress
							.replace("..", "@")
							.toLowerCase()
					: "";

				// Call Plugin
				if (this.$route.query.emailAddress) {
					this.waiverSign = await this.getReservation(
						this.emailAddress
					);
					this.waivers = this.waiverSign.waivers;

					// Check Email Addresses
					if (
						this.emailAddress == this.waiverSign.primaryEmailAddress
					)
						this.validEmail = true;
				}

				this.loading = false;
			},

			checkEmail() {
				if (
					this.emailAddress &&
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
						this.emailAddress
					) // eslint-disable-line no-useless-escape
				)
					return true;
				return false;
			},

			async setParams() {
				this.loading = true;

				if (
					this.$route.query.emailAddress !==
						this.emailAddress.replace("@", "..").toLowerCase() &&
					this.checkEmail()
				) {
					this.$router.push({
						name: "SurferWaiver",
						query: {
							...this.$route.query,
							emailAddress: this.emailAddress
								.replace("@", "..")
								.toLowerCase()
						}
					});
					await this.checkData();
				}

				if (!this.validEmail || !this.checkEmail()) {
					this.$refs["incorrectEmail"].classList.remove("opacity-0");
					window.setTimeout(
						function(element) {
							element.classList.add("opacity-0");
						},
						2500,
						this.$refs["incorrectEmail"]
					);
				}

				this.loading = false;
			}
		}
	};
</script>
